import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

const GuestMenu: React.FC = () => {
    return (
        <>
            <Button>
                <Link to={'/terms'} data-testid={'terms-link'}>
                    <Typography>Terms</Typography>
                </Link>
            </Button>
            <Button>
                <Link to={'/contact'} data-testid={'contact-link'}>
                    <Typography>Contact</Typography>
                </Link>
            </Button>
            <Button>
                <Link to={'/register'} data-testid={'register-link'}>
                    <Typography>Register</Typography>
                </Link>
            </Button>
            <Button>
                <Link to={'/login'} data-testid={'login-link'}>
                    <Typography>Login</Typography>
                </Link>
            </Button>
        </>
    );
};

export default GuestMenu;
