import React, { ReactNode } from 'react';

import { NavLink, PathMatch, useMatch, useResolvedPath } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type ResourcesLinkProps = {
    children?: ReactNode;
    to: string;
    style?: object;
    className?: string;
    redirect?: boolean;
    external?: boolean;
};

const ResourcesLink: React.FC<ResourcesLinkProps> = ({
    children,
    to,
    style,
    className,
    redirect = false,
    external,
    ...props
}) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    const [isHovering, setIsHovering] = React.useState(false);
    const theme = useTheme();
    const { classes } = styles(theme, isHovering, match)();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (redirect) {
            e.preventDefault();
            window.location.replace(to);
        }
    };

    const computedClass = () => {
        let computed;
        if (className) computed = className;
        if (!className) computed = classes.defaultLink;
        if (style) computed = '';
        return computed;
    };

    const computedStyle = () => {
        let computed;
        if (style) computed = style;
        if (!style) computed = {};
        if (className) computed = {};

        return computed;
    };

    return (
        <div style={{ flexGrow: 1 }}>
            {external ? (
                <a
                    className={computedClass()}
                    style={computedStyle()}
                    href={to}
                    onClick={handleClick}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    {...props}>
                    {children}
                </a>
            ) : (
                <NavLink
                    className={computedClass()}
                    style={computedStyle()}
                    to={to}
                    onClick={handleClick}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    {...props}>
                    {children}
                </NavLink>
            )}
        </div>
    );
};

const styles = (theme: Theme, isHovering: boolean, match: PathMatch<string> | null) =>
    makeStyles()(() =>
        ({
            defaultLink: {
                flexGrow: 1,
                width: '100%',
                textDecoration: isHovering ? 'underline' : 'none',
                backgroundColor:
                    isHovering || match ? theme.palette.background.default : theme.palette.background.paper,
                alignItems: 'center',
                justifyContent: 'initial',
                flexDirection: 'row',
                display: 'flex',
                paddingLeft: theme.spacing(1),
                color: theme.palette.text.primary,
            }
        }));

export default ResourcesLink;
