import apiSlice from '../apiSlice';

const characterApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCharacters: builder.query<Character[], null>({
            query: () => `characters`,
        }),
        getCharacter: builder.mutation({
            query: ({ characterId }) => ({
                url: `characters/${characterId}`,
            }),
        }),
    }),
});

export default characterApi;
export const { useGetCharactersQuery, useGetCharacterMutation } = characterApi;
