import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Channel } from 'laravel-echo/dist/channel';
import echo from '../components/Echo';
import Echo from 'laravel-echo';
import { setEchoStatus, setSocketId } from '../redux/reducers/appSlice';

type EchoContextType = { echo: Echo | null; privateChannel: Channel | null };

interface EchoProps {
    children?: ReactNode;
}

export const EchoContext = createContext<EchoContextType>({ echo: null!, privateChannel: null! });

const EchoProvider = ({ children }: EchoProps) => {
    const disabled = import.meta.env.VITE_DISABLE_WEBSOCKETS === 'true';
    const auth = useAppSelector((state) => state.auth);
    const user = useAppSelector((state) => state.user);
    const socket = useAppSelector((state) => state.app.socket);
    const [privateChannel, setPrivateChannel] = useState<Channel | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (disabled) {
            return;
        }

        //We have auth but are not connected to WS
        if (auth.status && auth.accessToken && socket !== 'connected') {
            //user logged out or pusher disconnected, tell pusher to reconnect
            echo.connector.pusher.connection.state === 'disconnected' && echo.connector.pusher.connect();
        }
        //User Logged out/lost session
        if (!auth.accessToken && socket === 'connected') {
            echo.leaveAllChannels();
            echo.connector.pusher.disconnect();
            setPrivateChannel(null);
        }
    }, [auth]);

    //Setup State changed listener
    useEffect(() => {
        if (disabled) {
            return;
        }

        const onStateChanged = ({ current, previous }: { current: string; previous: 'string' }) => {
            if (current === 'connected') {
                auth.accessToken && dispatch(setEchoStatus('connected'));
                dispatch(setSocketId(echo.socketId()));
            }
        };
        echo.connector.pusher.connection.bind('state_change', onStateChanged);

        return () => {
            echo.connector.pusher.connection.unbind('state_change', onStateChanged);
        };
    }, []);

    //Connect to user's private channel once we are connected
    useEffect(() => {
        if (disabled) {
            return;
        }

        if (user.data && !privateChannel) {
            setPrivateChannel(echo.private(`nightscloak.user.${user.data?.id}`));
        }
    }, [socket, user]);

    return <EchoContext.Provider value={{ echo: echo, privateChannel }}>{children}</EchoContext.Provider>;
};

const useEcho = () => {
    return { ...useContext(EchoContext) };
};

const useEchoPrivateListener = () => {
    const { echo, privateChannel } = useContext(EchoContext);

    return useCallback(
        (channel: string, callback: Function) => {
            if (echo && privateChannel) {
                privateChannel.listen(channel, callback);

                return () => {
                    privateChannel.stopListening(channel);
                };
            } else {
                return () => {};
            }
        },
        [echo, privateChannel]
    );
};

export default EchoProvider;
export { useEcho, useEchoPrivateListener };
