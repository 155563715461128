import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { store } from '../redux/store';

const disabled = import.meta.env.VITE_DISABLE_WEBSOCKETS === 'true';

const options = {
    broadcaster: disabled ? 'null' : 'pusher',
    client: disabled
        ? null
        : new Pusher(`${import.meta.env.VITE_PUSHER_APP_KEY}`, {
              cluster: 'NaN',
              wsPath: import.meta.env.VITE_PUSHER_APP_PATH,
              wsHost: import.meta.env.VITE_URI,
              wssPort: parseInt(import.meta.env.VITE_PUSHER_APP_WSS_PORT as string),
              wsPort: parseInt(import.meta.env.VITE_PUSHER_APP_WS_PORT as string),
              forceTLS: (import.meta.env.VITE_DEPLOY_ENV as string) !== 'local',
              enableStats: false,
              enabledTransports: ['ws', 'wss'],
              channelAuthorization: {
                  transport: 'ajax',
                  endpoint: `${import.meta.env.VITE_SCHEMA}://${import.meta.env.VITE_URI}${
                      import.meta.env.VITE_PORT ? `:${import.meta.env.VITE_PORT}` : ''
                  }/${import.meta.env.VITE_PREFIX}/broadcasting/auth`,
                  headersProvider: () => {
                      const state = store.getState();
                      if (!state.auth.accessToken) {
                          return {};
                      }

                      return {
                          Accept: 'application/json',
                          Authorization: `Bearer ${state.auth.accessToken}`,
                      };
                  },
              },
          }),
};

const echo = new Echo(options);

export default echo;
