import { FC, Suspense, SyntheticEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { CssBaseline, Theme, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../redux/hooks';
import { usePortal } from '../../contexts/PortalContext';
import Logo from '../../images/logo.png';
import AdminPortalMenu from '../../components/Navbar/AdminPortalMenu';
import UserDropdown from '../../components/Navbar/UserDropdown';
import GuestMenu from '../../components/Navbar/GuestMenu';
import AppBar from '../../components/AppBar';
import { Dots } from 'react-activity';
import Drawer from '../../components/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import DrawerHeader from '../../components/DrawerHeader';
import Sidebar from '../../components/Sidebar';
import Content from '../../components/Content';

const Main: FC = () => {
    const auth = useAppSelector((state) => state.auth.status);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [animation, setAnimation] = useState<0 | 1 | null>(null);
    const { portal, isAssetEditor } = usePortal();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { classes } = useStyles();

    useEffect(() => {
        !mobile && setDrawerOpen(false);
    }, [mobile]);

    useLayoutEffect(() => {
        if ((drawerOpen && animation === null) || animation === 0) {
            setAnimation(1);
            return;
        }
        if (!drawerOpen && animation === 1) {
            setAnimation(0);
        }
    }, [drawerOpen]);

    const home = useMemo(() => {
        if (portal === 'Admin') {
            return '/admin';
        }

        return '/';
    }, [portal, auth]);

    const handleDrawerToggle = (): void => {
        setDrawerOpen((isOpen) => !isOpen);
    };

    return (
        <>
            <CssBaseline />
            <AppBar
                position={'fixed'}
                className={classes.appBar}
                enableColorOnDark
                color={isAssetEditor ? 'transparent' : 'default'}
                elevation={0}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        size="large"
                        // edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            marginLeft: '1px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        }} //Override to keep inline with menu icons ....
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to={home} className={classes.logo}>
                        <img src={Logo} height={45} alt={'NC Logo'} />
                    </Link>
                    <div className={classes.menu}>
                        {portal !== 'Landing' && (
                            <>
                                {mobile ? (
                                    <MenuIcon
                                        sx={{ marginRight: theme.spacing(2) }}
                                        onClick={() => setDrawerOpen(!drawerOpen)}
                                    />
                                ) : auth ? (
                                    <>
                                        {portal === 'Admin' && <AdminPortalMenu />}
                                        <UserDropdown />
                                    </>
                                ) : (
                                    <GuestMenu />
                                )}
                            </>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant={'permanent'}
                open={drawerOpen}
                animation={animation}
                onClose={function (event: SyntheticEvent<{}, Event>): void {}}
                onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
            >
                <Sidebar open={drawerOpen} setOpen={handleDrawerToggle} animation={animation} />
            </Drawer>
            <Content className={classes.main} open={drawerOpen} animation={animation}>
                <DrawerHeader />
                <Suspense fallback={<Dots />}>
                    <Outlet />
                </Suspense>
            </Content>
        </>
    );
};

const useStyles = makeStyles()((theme: Theme) => ({
    // root: {
    //     flex: 1,
    //     minHeight: '100%',
    //     display: 'flex',
    // },
    appBar: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiToolbar-root-toolbar': {
            padding: 0,
            backgroundColor: 'transparent',
        },
    },

    toolbar: {
        flex: 1,
        display: 'flex',
        width: '100%',
        backgroundColor: 'transparent',
    },

    main: {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[100],
        backgroundColor: theme.palette.mode === 'light' ? 'black' : 'black',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },

    logo: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
    },

    menu: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

export default Main;
