import apiSlice from '../apiSlice';

const authApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        heartbeat: builder.query<Heartbeat, null>({
            query: () => `/heartbeat`,
        }),
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: ({ username, password }) => ({
                url: 'auth/login',
                method: 'POST',
                body: {
                    username,
                    password,
                    client_id: import.meta.env.VITE_CLIENT_ID,
                    client_secret: import.meta.env.VITE_CLIENT_SECRET,
                    platform: 'web',
                    grant_type: 'password',
                    scope: '',
                },
            }),
        }),
        forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
            query: ({ email }) => ({
                url: 'auth/passwords/forgot',
                method: 'POST',
                body: { email },
            }),
        }),
        resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
            query: ({ token, email, password, password_confirmation }) => ({
                url: 'auth/passwords/reset',
                method: 'POST',
                body: {
                    token,
                    email,
                    password,
                    password_confirmation,
                },
            }),
        }),
        register: builder.mutation<RegisterResponse, RegisterRequest>({
            query: ({ first, last, email, code, terms, password, password_confirmation }) => ({
                url: 'auth/register',
                method: 'POST',
                body: {
                    first,
                    last,
                    email,
                    code,
                    terms,
                    password,
                    password_confirmation,
                },
            }),
        }),
        contact: builder.mutation<ContactResponse, ContactRequest>({
            query: ({ name, email, message }) => ({
                url: 'contact',
                method: 'POST',
                body: {
                    name,
                    email,
                    message,
                },
            }),
        }),
        resendEmailVerification: builder.mutation<ResendEmailVerificationResponse, ResendEmailVerificationRequest>({
            query: ({ email }) => ({
                url: 'auth/email/resend',
                method: 'POST',
                body: { email },
            }),
        }),
        verifyEmail: builder.query<VerifyEmailResponse, { userId: string; emailHash: string }>({
            query: ({ userId, emailHash }) => ({
                url: `auth/email/verify/${userId}/${emailHash}`,
                method: 'GET',
            }),
        }),
    }),
});

export default authApi;
export const {
    useHeartbeatQuery,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useLoginMutation,
    useRegisterMutation,
    useContactMutation,
    useResendEmailVerificationMutation,
    useVerifyEmailQuery,
} = authApi;
