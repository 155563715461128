import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useGetCharactersQuery } from '../redux/features/characterApi';
import { Levels } from 'react-activity';
import { useNavigate } from 'react-router-dom';

const CharactersScreen = () => {
    const { classes } = useStyles();
    const characters = useGetCharactersQuery(null);
    const navigate = useNavigate();
    // state = {
    //     image: null,
    //     route: '',
    //     type: 'GET',
    //     key: '',
    //     isLoading: this.props.characters.length > 0,
    //     characterModal: false,
    // };
    //
    // constructor(props) {
    //     super(props);
    //     // if(this.props.characters.lastCheck + (5 * 60000) <= Date.now()){
    //     this.props.getCharacters();
    //     // }
    // }

    // async componentDidMount() {
    //     console.log('props', this.props);
    //     if (this.state.isLoading) {
    //         setTimeout(() => {
    //             this.setState({
    //                 isLoading: false,
    //             });
    //         }, 1000);
    //     }
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {}
    //
    // render() {
    //     const { classes } = this.props;
    //     if (this.state.isLoading) {
    //         return (
    //             <div className={classes.root}>
    //                 <Spinner />
    //             </div>
    //         );
    //     } else {

    const group = (character: Character) => {
        switch (character.species_type) {
            case 'App\\Models\\Vampire':
                return character.species.sect[0] ? character.species.sect[0].name : '-';
        }
    };
    //
    const subGroup = (character: Character) => {
        switch (character.species_type) {
            case 'App\\Models\\Vampire':
                return character.species.sect[0] ? character.species.clan[0].name : '-';
        }
    };

    // const showAddCharacter = () => {
    //     this.setState((state) => ({ characterModal: !state?.characterModal }));
    // };
    if (!characters.data) return <Levels style={{ fontSize: 75 }} />;

    return (
        <>
            <div className={classes.root}>
                <div className={classes.toolBar}>
                    <Button className={classes.addCharacterButton}>Add Character</Button>
                </div>

                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table className={classes.table} aria-label={'Characters'}>
                        <TableHead>
                            <TableRow aria-label={'spanning table'}>
                                <TableCell className={classes.tableCell}>Character Name</TableCell>
                                <TableCell className={classes.tableCell}>Type</TableCell>
                                <TableCell className={classes.tableCell}>Group</TableCell>
                                <TableCell className={classes.tableCell}>SubGroup</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {characters.data &&
                                Object.values(characters.data).map((character) => {
                                    if (character.hasOwnProperty('id')) {
                                        // console.log("species", character.species.sect[0])
                                        return (
                                            <TableRow
                                                key={`${character.id}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => navigate(`/characters/${character.id}`)}
                                            >
                                                <TableCell className={classes.tableCell}>{character.name}</TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {character.species_type.split('App\\Models\\')}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>{group(character)}</TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {subGroup(character)}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {/*<Modal*/}
            {/*    open={this.state.characterModal}*/}
            {/*    onClose={this.showAddCharacter}*/}
            {/*    aria-labelledby={'add-character'}*/}
            {/*    aria-describedby={'add-character'}*/}
            {/*>*/}
            {/*    <div className={classes.modal}>*/}
            {/*        <h2 className={classes.modalHeader}>Add a Character</h2>*/}
            {/*        <TextField id="character_name" label="Character Name" variant="filled" />*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        alignItems: 'center',
    },
    header: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tableContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '75%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    table: {
        textAlign: 'center',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    tableCell: {
        textAlign: 'center',
    },
    modal: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        width: '75%',
        [theme.breakpoints.down('md')]: {
            width: '50%',
        },
        height: 300,
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
        },
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    modalHeader: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: '50px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toolBar: {
        width: '100%',
        height: 100,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    addCharacterButton: {
        width: 200,
        height: 100 - 20,
        float: 'right',
    },
}));

export default CharactersScreen;
