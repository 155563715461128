import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useAppSelector } from '../redux/hooks';
import { useGetUserQuery } from '../redux/features/userApi';

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    const auth = useAppSelector((state) => state.auth.status);
    const user = useGetUserQuery(null, { skip: !auth });

    useEffect(() => {
        if (!window.location.href.includes('localhost') || !window.location.href.includes('127.0.0.1')) {
            // console.log("init GA", user.data?.id)
            ReactGA.initialize('UA-216355396-1', {
                debug: false,
                // testMode: true,
                gaOptions: {
                    cookieDomain: 'none',
                },
            });
            setInitialized(true);
        }
    }, [user, auth]);

    //Set User ID
    useEffect(() => {
        if (initialized)
            ReactGA.set({
                userId: auth && user.data?.id ? user.data.id : undefined,
            });
    }, [auth, initialized, user]);

    //Page View
    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(`${location.pathname} ${location.search}`);
        }
    }, [initialized, location]);
};

export default usePageTracking;
