import { Button, Typography } from '@mui/material';
import React from 'react';

const AdminPortalMenu: React.FC = () => {
    return (
        <>
            <Button>
                <a
                    target={'_blank'}
                    href={`https://${
                        import.meta.env.PROD ? import.meta.env.VITE_URI : 'nightscloak.dev'
                    }/mission/telescope`}
                    rel="noreferrer"
                >
                    <Typography>Telescope</Typography>
                </a>
            </Button>
            <Button>
                <a
                    target={'_blank'}
                    href={`https://${
                        import.meta.env.PROD ? import.meta.env.VITE_URI : 'nightscloak.dev'
                    }/mission/horizon`}
                    rel="noreferrer"
                >
                    <Typography>Horizon</Typography>
                </a>
            </Button>
        </>
    );
};

export default AdminPortalMenu;
