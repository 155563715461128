import { ReactNode, createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router';

type Portal = 'Landing' | 'User' | 'Admin';

type PortalProps = {
    children?: ReactNode;
};

type PortalContextProps = {
    portal: Portal;
    isAssetEditor: boolean;
    hideTopNavbar: boolean;
    setHideTopNavbar: Dispatch<SetStateAction<boolean>>;
};

const PortalContext = createContext<PortalContextProps>({
    portal: 'Landing',
    isAssetEditor: false,
    hideTopNavbar: false,
    setHideTopNavbar: null!,
});

const PortalProvider = ({ children }: PortalProps) => {
    const [portal, setPortal] = useState<Portal>('Landing');
    const [isAssetEditor, setIsAssetEditor] = useState<boolean>(false);
    const [hideTopNavbar, setHideTopNavbar] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        setIsAssetEditor(location.pathname.includes('/editor'));

        if (location.pathname === '/') {
            setPortal('Landing');
            return;
        }

        if (location.pathname.includes('/admin')) {
            setPortal('Admin');
            return;
        }

        setPortal('User');
    }, [location]);

    return (
        <PortalContext.Provider value={{ portal, isAssetEditor, hideTopNavbar, setHideTopNavbar }}>
            {children}
        </PortalContext.Provider>
    );
};

export const usePortal = () => {
    return useContext(PortalContext);
};

export default PortalProvider;
