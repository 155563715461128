import { styled } from '@mui/material/styles';
import { drawerWidth } from '../config/theme';
import { keyframes } from '@mui/material';

type ContentProps = {
    open: boolean;
    animation: 0 | 1 | null;
};

const Content = styled('div', {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'animation',
})<ContentProps>(({ theme, open, animation }) => ({
    marginLeft: `calc(${theme.spacing(8)} + 2px)`,
    zIndex: 0,
    [theme.breakpoints.up('sm')]: {
        ...(animation === 1 && {
            animation: `${keyframes`
              0% {
                margin-left: calc(${theme.spacing(8)}px + 1px);
              }
              100% {
                margin-left: ${drawerWidth}px;
              }
            `} .6s forwards ease`,
        }),
        ...(animation === 0 && {
            animation: `${keyframes`
              0% {
                margin-left: ${drawerWidth}px;
              }
              100% {
                margin-left: calc(${theme.spacing(8)}px + 1px);
              }
            `} .6s forwards ease`,
        }),
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
    },
}));

export default Content;
