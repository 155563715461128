import { createTheme, darkScrollbar } from '@mui/material';

export const drawerWidth = 240;

let theme = (prefersDarkMode: Boolean) =>
    createTheme({
        components: {
            MuiAppBar: {
                defaultProps: {
                    enableColorOnDark: true,
                },
                styleOverrides: {
                    root: {
                        // backgroundColor: prefersDarkMode ? '#5B3662' : '#824e8d',
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: prefersDarkMode ? darkScrollbar() : null,
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {
                        '*::-webkit-scrollbar': {
                            display: 'none',
                            //chrome
                        },
                        '*::-webkit-scrollba-thumb': {
                            //chrome
                        },
                        '*:MsOverflowStyle': 'none',
                    },
                },
            },
        },
        spacing: 8,
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
            background: {
                default: '#232323',
                paper: '#363636',
            },
            text: {
                primary: prefersDarkMode ? '#FFFFFF' : '#FFFFFF',
                secondary: prefersDarkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0, 0, 0, 0.54)',
                disabled: prefersDarkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.38)',
            },
            primary: {
                main: '#000000',
                light: '#545454',
                dark: '#262626',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#f50057',
                light: '#F73378',
                dark: '#AB003C',
                contrastText: '#FFFFFF',
            },
            error: {
                main: '#F44336',
                light: '#e57373',
                dark: '#d32f2f',
                contrastText: '#FFFFFF',
            },
            warning: {
                main: '#ff9800',
                light: '#ffb74d',
                dark: '#f57c00',
                contrastText: 'rgba(0,0,0,0.87)',
            },
            info: {
                main: '#2196f3',
                light: '#64b5f6',
                dark: '#1976d2',
                contrastText: '#FFFFFF',
            },
            success: {
                main: '#4caf50',
                light: '#81c784',
                dark: '#388e3c',
                contrastText: 'rgba(0,0,0,0.87)',
            },
            divider: 'rgba(255, 255, 255, 0.12)',
        },
        transitions: {
            duration: {
                shortest: 150,
                shorter: 200,
                short: 250,
                // most basic recommended timing
                standard: 300,
                // this is to be used in complex animations
                complex: 375,
                // recommended when something is entering screen
                enteringScreen: 225,
                // recommended when something is leaving screen
                leavingScreen: 195,
            },
            easing: {
                // This is the most common easing curve.
                easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                // Objects enter the screen at full velocity from off-screen and
                // slowly decelerate to a resting point.
                easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                // Objects leave the screen at full velocity. They do not decelerate when off-screen.
                easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                // The sharp curve is used by objects that may return to the screen at any time.
                sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
            },
        },
    });

export default theme;
