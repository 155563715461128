import { createSlice } from '@reduxjs/toolkit';
import authApi from '../features/authApi';

const initialState: AuthState = {
    status: false,
    lastCheck: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        logout: (state) => (state = initialState),
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.heartbeat.matchFulfilled, (state, { payload }) => {
                state.status = payload.auth;
            })
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
                state.status = true;
                state.accessToken = payload.access_token;
                state.refreshToken = payload.refresh_token;
                state.expiresIn = JSON.stringify(new Date(payload.expires_in));
                state.lastCheck = JSON.stringify(new Date());
            });
    },
});

export default authSlice;
export const { logout } = authSlice.actions;
