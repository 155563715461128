import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Location } from 'react-router-dom';

const HistoryContext = createContext<Location[]>([]);

interface PortalProps {
    children?: ReactNode;
}

const HistoryProvider = ({ children }: PortalProps) => {
    const location = useLocation();
    const [history, updateHistory] = useState<Location[]>([]);

    useEffect(() => {
        history[history.length - 1] !== location && updateHistory([...history, location]);
    }, [location]);

    return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>;
};

export const useHistory = () => {
    return useContext(HistoryContext);
};

export default HistoryProvider;
