import { createListenerMiddleware } from '@reduxjs/toolkit';
import { logout } from '../reducers/authSlice';
import { persistor } from '../store';
import apiSlice from '../apiSlice';

const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
    actionCreator: logout,
    effect: async (action, api) => {
        api.cancelActiveListeners();
        apiSlice.util.resetApiState();
        await persistor.purge();
        await persistor.persist();
    },
});

export default authMiddleware;
