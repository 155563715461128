import { makeStyles } from 'tss-react/mui';
import {
    Avatar,
    Button,
    Divider,
    ListItemIcon,
    Menu,
    MenuItem,
    MenuList,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Sentry } from 'react-activity';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import apiSlice from '../../redux/apiSlice';
import userAvatar from '../../images/users.png';
import authSlice from '../../redux/reducers/authSlice';
import { persistor } from '../../redux/store';
import {
    AdminPanelSettings,
    FactCheck,
    Group,
    Logout,
    ManageAccounts,
    QuestionAnswer,
} from '@mui/icons-material';
import { usePortal } from '../../contexts/PortalContext';

type MenuObject = {
    title: string;
    icon?: JSX.Element;
    ignore?: boolean;
    navigateTo?: string;
    isLogout?: boolean;
    isHeader?: boolean;
    selected?: boolean;
    divider?: { top?: boolean; bottom?: boolean };
}[];

const UserDropdown = () => {
    const user = useAppSelector((state) => state.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { portal, isAssetEditor } = usePortal();
    const { classes } = styles({ open });

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const navigateTo = useCallback((path: string) => {
        setAnchorEl(null);
        navigate(path);
    }, []);

    const logout = useCallback(() => {
        setAnchorEl(null);
        dispatch(authSlice.actions.logout());
        dispatch(apiSlice.util.resetApiState());
        persistor.purge();
        persistor.persist();
    }, []);

    const menuItems = useMemo((): MenuObject => {
        return [
            {
                title: 'Portal',
                isHeader: true,
                ignore: !user.data || !user.data.is_developer,
            },
            {
                title: 'User',
                icon: <Group fontSize={'small'} />,
                navigateTo: '/',
                selected: portal === 'User',
                ignore: !user.data || !user.data.is_developer,
            },

            {
                title: 'Admin',
                icon: <AdminPanelSettings fontSize={'small'} />,
                navigateTo: '/admin',
                selected: portal === 'Admin',
                ignore: !user.data || !user.data.is_admin,
                divider: { bottom: true },
            },
            {
                title: 'Account Settings',
                icon: <ManageAccounts fontSize={'small'} />,
                navigateTo: '/settings',
            },
            {
                title: 'Terms',
                icon: <FactCheck fontSize={'small'} />,
                navigateTo: '/terms',
                divider: { top: true },
            },
            {
                title: 'Contact',
                icon: <QuestionAnswer fontSize={'small'} />,
                navigateTo: '/contact',
                divider: { bottom: true },
            },
            {
                title: 'Logout',
                icon: <Logout fontSize={'small'} />,
                isLogout: true,
            },
        ].filter((item) => item.ignore !== true);
    }, [user.data, portal]);

    if (!user.data) {
        return <Sentry style={{ marginTop: 5, marginRight: 5 }} />;
    }

    return (
        <>
            <Button
                id={'user_menu_button'}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    color: '#FFF',
                }}
                onClick={handleClick}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup={'true'}
                aria-expanded={open ?? undefined}
            >
                <Avatar
                    sx={{
                        height: 25,
                        width: 25,
                        marginRight: theme.spacing(1),
                    }}
                    src={user.data.avatar_route ?? userAvatar}
                    alt={`${user.data.name}'s Avatar`}
                />
                <Typography>{user.data.name}</Typography>
                <ArrowDropDownIcon className={classes.mirror} />
            </Button>
            <Menu
                id={'user_menu'}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: isAssetEditor ? 0 : 10,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& ul': {
                            paddingTop: 0.5,
                        },
                    },
                }}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
                <MenuList dense={true}>
                    {menuItems.map((item, index) => (
                        <span key={index}>
                            {item.isHeader ? (
                                <>
                                    <Typography sx={{ marginLeft: 2 }} variant={'overline'}>
                                        {item.title}
                                    </Typography>
                                    <Divider />
                                </>
                            ) : (
                                <>
                                    {item.divider?.top && <Divider sx={{ marginY: 1 }} />}
                                    <MenuItem
                                        selected={item.selected}
                                        onClick={() => (item.isLogout ? logout() : navigateTo(item.navigateTo ?? '/'))}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        {item.title}
                                    </MenuItem>
                                    {item.divider?.bottom && <Divider sx={{ marginY: 1 }} />}
                                </>
                            )}
                        </span>
                    ))}
                </MenuList>
            </Menu>
        </>
    );
};

const styles = makeStyles<{ open: boolean }>()((theme: Theme, { open }) => ({
    mirror: {
        ...(open && {
            transform: 'rotate(180deg)',
        }),
    },
}));

export default UserDropdown;
