import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { ThemeProvider } from '@mui/material';
import routes from './config/routes';
import defaultTheme from './config/theme';
import { useMatch, useNavigate, useRoutes } from 'react-router-dom';
import { updateTheme } from './redux/reducers/appSlice';
import usePageTracking from './components/usePageTracking';
import { useHeartbeatQuery } from './redux/features/authApi';
import { useGetUserQuery } from './redux/features/userApi';
import HistoryProvider from './contexts/HistoryContext';
import EchoProvider from './contexts/EchoContext';
import 'react-activity/dist/Levels.css';
import 'react-activity/dist/Digital.css';
import 'react-activity/dist/Sentry.css';
import 'react-activity/dist/Windmill.css';
import 'react-activity/dist/Spinner.css';

// import {useHeartbeatQuery} from "./redux/features/authApi";

function App() {
    //Init Redux
    useHeartbeatQuery(null, { pollingInterval: 300000 });
    const dispatch = useAppDispatch();
    const theme = useAppSelector((state) => state.app.theme);
    const maintenance = useAppSelector((state) => state.app.maintenance);
    const auth = useAppSelector((state) => state.auth.status);
    const user = useGetUserQuery(null, {
        skip: !auth,
        refetchOnMountOrArgChange: true,
    });

    const settings = useAppSelector((state) => state.user.settings);
    const navigate = useNavigate();
    const matchRoute = useMatch('maintenance');

    usePageTracking();
    //Get OS Dark/Lite mode as initial default
    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = true;

    useEffect(() => {
        dispatch(updateTheme(prefersDarkMode));
    }, [prefersDarkMode, dispatch]);

    let handleMaintenanceSet = useCallback(() => {
        !matchRoute && navigate('/maintenance');
    }, [matchRoute, navigate]);

    useEffect(() => {
        maintenance && handleMaintenanceSet();
        !maintenance && matchRoute && user.refetch();
    }, [handleMaintenanceSet, maintenance, matchRoute, user]);

    const configTheme = useCallback(() => {
        return defaultTheme(theme);
    }, [theme]);

    // const defaultTheme = useMemo(() => configTheme, [configTheme]);
    // const defaultTheme = configTheme;

    //Main Router
    const router = useRoutes(routes(auth, settings));

    return (
        <EchoProvider>
            <HistoryProvider>
                <ThemeProvider theme={configTheme()}>{router}</ThemeProvider>
            </HistoryProvider>
        </EchoProvider>
    );
}

export default App;
