import { MiddlewareAPI, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { setMaintenanceMode } from '../reducers/appSlice';
import { RootState } from '../store';

export const maintenanceMiddleware: Middleware =
    ({ getState, dispatch }: MiddlewareAPI) =>
    (next) =>
    (action) => {
        // Did we Reject?
        if (isRejectedWithValue(action)) {
            switch (action.payload.status) {
                case 503 /* dispatch change to app status */:
                    dispatch(setMaintenanceMode(true));
                    break;
            }
        }

        if (action.type === 'api/executeQuery/fulfilled' && (getState() as RootState).app.maintenance) {
            dispatch(setMaintenanceMode(false));
        }
        return next(action);
    };
