const generateQuery = (options: object | undefined): string => {
    if (!options) {
        return '';
    }

    const list = Object.entries(options)
        .filter((option) => option[1] !== undefined)
        .map((option, index) => {
            let item = index === 0 ? '' : '&';
            item += `${option[0]}=${option[1]}`;
            return item;
        })
        .join('');

    return `?${list}`;
};

export { generateQuery };
