import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AppState = {
    drawer: true,
    theme: true, //darkTheme true/false
    maintenance: false,
    currentGameId: '',
    socket: 'disconnected',
    socketId: null,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateTheme: (state, action: PayloadAction<boolean>) => {
            state.theme = action.payload;
        },
        setMaintenanceMode: (state, action: PayloadAction<boolean>) => {
            state.maintenance = action.payload;
        },
        setEchoStatus: (state, action: PayloadAction<string>) => {
            state.socket = action.payload;
        },
        setSocketId: (state, action: PayloadAction<string | null>) => {
            state.socketId = action.payload;
        },
    },
});

export const { updateTheme, setMaintenanceMode, setEchoStatus, setSocketId } =
    appSlice.actions;

export default appSlice;
