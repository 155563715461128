import { createMigrate } from 'redux-persist';

/**
 * Latest Migration Version is the current Redux RootState
 */

type AuthRootStateV2 = AuthState;

type AuthRootStateV1 = Omit<AuthRootStateV2, 'lastCheck' & 'accessToken' & 'refreshToken' & 'expiresIn'>;

const persistMigrations = {
    2: async (state: AuthRootStateV1): Promise<AuthRootStateV2> => ({
        ...state,
        lastCheck: null,
        accessToken: null,
        refreshToken: null,
        expiresIn: null,
    }),
};

type MigrationState = AuthRootStateV1 | AuthRootStateV2;

export const authMigrate = createMigrate<MigrationState>(persistMigrations);

export const authVersion = 2;
