import { Avatar, Fade, IconButton, keyframes, Typography } from '@mui/material';
import userAvatar from '../../images/users.png';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import authSlice from '../../redux/reducers/authSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery } from '../../redux/features/userApi';
import { FC } from 'react';
import { drawerWidth } from '../../config/theme';

const UserMenu: FC<{ animation: 0 | 1 | null; closedWidth: string }> = ({ animation, closedWidth }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const auth = useAppSelector((state) => state.auth.status);
    const user = useGetUserQuery(null, { skip: !auth });
    const { classes } = useStyles({ animation, auth, closedWidth });

    const logout = () => {
        dispatch(authSlice.actions.logout());
        navigate('/login');
    };

    return (
        <div className={classes.root}>
            <div
                style={{
                    flex: 1,
                    width: animation === 0 ? closedWidth : drawerWidth,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    sx={{
                        height: 45,
                        width: 45,
                    }}
                    src={user.currentData?.avatar_route ?? userAvatar}
                    alt={user.currentData?.name ? `${user.currentData.name}'s Avatar` : ''}
                />
                <Typography className={classes.userName}>{user.currentData?.name ?? ''}</Typography>
            </div>
            <div className={classes.userMenuWrapper}>
                <Fade in={animation === 1}>
                    <div className={classes.userMenu}>
                        <IconButton onClick={() => navigate('/mail')}>
                            <MailIcon />
                        </IconButton>
                        <IconButton onClick={() => navigate('/settings')}>
                            <SettingsIcon />
                        </IconButton>
                        <IconButton onClick={logout}>
                            <LogoutIcon />
                        </IconButton>
                    </div>
                </Fade>
            </div>
        </div>
    );
};

const useStyles = makeStyles<{ animation: 0 | 1 | null; auth: boolean; closedWidth: string }>()(
    (theme, { animation, auth, closedWidth }) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            visibility: 'hidden',
            width: 0,
            height: 0,
            ...(auth && {
                visibility: 'visible',
                justifyContent: 'center',
                paddingTop: theme.spacing(2),
                width: closedWidth,
                height: 60,
                ...(animation === 0 && {
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    animation: `${keyframes`
                      0% {
                        height: 100px;
                      }
                      100% {
                        width: ${closedWidth};
                        height: 60px;
                      }
                    `} .5s forwards ease`,
                }),
                ...(animation === 1 && {
                    minHeight: 60,
                    animation: `${keyframes`
                      0% {
                        width: ${closedWidth}px;
                        height: 60px;
                      }
                      100% {
                        width: ${drawerWidth}px;
                        height: 100px;
                        visibility: visible;
                      }
                    `} .5s forwards ease`,
                }),
            }),
        },
        userName: {
            display: 'flex',
            visibility: 'hidden',
            width: '0',
            ...(animation === 0 && {
                animation: `${keyframes`
                  0% {
                  }
                  100% {
                    visibility: hidden;
                    width: 0;
                    margin-left: 0;
                  }
                `} .5s forwards ease`,
            }),
            ...(animation === 1 && {
                animation: `${keyframes`
                  0% {
                    width: 0;
                    visibility: hidden;
                    margin-left: 2px;
                  }
                  100% {
                    visibility: visible;
                    width: auto;
                    margin-left: ${theme.spacing(2)};
                  }
                `} .5s forwards ease`,
            }),
        },

        userMenuWrapper: {
            // flex: 1,
            // display: 'flex',
            // width: '100%',
            // alignItems: 'center',
            // flexDirection: 'row',
        },

        userMenu: {
            display: 'flex',
            width: 0,
            height: 0,
            visibility: 'hidden',
            justifyContent: 'space-evenly',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            ...(animation === 0 && {
                animation: `${keyframes`
                  0% {
                    width: 100%;
                    height: 45px;
                    visibility: visible;
                  }
                  100% {
                    visibility: hidden;
                    width: 0;
                    height: 0;
                  }
                `} .5s forwards ease`,
            }),
            ...(animation === 1 && {
                animation: `${keyframes`
                  0% {
                    width: 0;
                    visibility: hidden;
                  }
                  100% {
                    width: 100%;
                    height: 45px;
                    visibility: visible;
                  }
                `} .5s forwards ease`,
            }),
        },
    })
);

export default UserMenu;
