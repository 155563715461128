import apiSlice from '../apiSlice';
import { generateQuery } from '../../components/QueryStrings';

const userApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<User, null>({
            query: () => ({
                url: 'user',
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getUserLogins: builder.query<UserLoginsResponse, null>({
            query: () => ({
                url: 'user/logins?limit=10',
                method: 'GET',
            }),
        }),
        updateUserAvatar: builder.mutation<UpdateAvatarResponse, UpdateUserAvatarRequest>({
            query: ({ avatar }) => ({
                url: 'user/avatar',
                method: 'POST',
                body: avatar,
            }),
            invalidatesTags: ['User'],
        }),
        deleteUserAvatar: builder.mutation<void, void>({
            query: () => ({
                url: 'user/avatar',
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
        updateUserName: builder.mutation<UpdateNameResponse, UpdateUserNameRequest>({
            query: ({ first, last }) => ({
                url: 'user/name',
                method: 'PUT',
                body: { first, last },
            }),
            // invalidatesTags: ['User'],
        }),
        updateUserEmail: builder.mutation<UpdateEmailResponse, UpdateUserEmailRequest>({
            query: ({ current_password, email }) => ({
                url: 'user/email',
                method: 'PUT',
                body: { current_password, email },
            }),
            invalidatesTags: ['User'],
        }),
        updateUserPassword: builder.mutation<UpdatePasswordResponse, UpdateUserPasswordRequest>({
            query: ({ current_password, password, password_confirmation }) => ({
                url: 'user/password',
                method: 'PUT',
                body: { current_password, password, password_confirmation },
            }),
        }),
        deleteUserAccount: builder.mutation<DeleteAccountResponse | void, DeleteUserAccountRequest>({
            query: ({ current_password }) => ({
                url: 'user/delete',
                method: 'POST',
                body: { current_password },
            }),
        }),
        adminGetUsers: builder.mutation<AdminGetUsersResponse, AdminGetUsersRequest>({
            query: ({ options }) => {
                return {
                    url: `admin/users${generateQuery(options)}`,
                    method: 'GET',
                };
            },
        }),
        adminUpdateUser: builder.mutation<User, AdminUpdateUserRequest>({
            query: ({ userId, is_enabled, is_admin, is_developer, is_verified }) => ({
                url: `admin/users/${userId}`,
                method: 'PUT',
                body: { is_enabled, is_admin, is_developer, is_verified },
            }),
        }),
        adminArchiveUser: builder.mutation<null, AdminArchiveUserRequest>({
            query: ({ userId }) => ({
                url: `admin/users/${userId}`,
                method: 'DELETE',
            }),
        }),
        adminRestoreUser: builder.mutation<User, AdminRestoreUserRequest>({
            query: ({ userId }) => ({
                url: `admin/users/${userId}/restore`,
                method: 'PUT',
            }),
        }),
    }),
});

export default userApi;
export const {
    useGetUserQuery,
    useGetUserLoginsQuery,
    useUpdateUserAvatarMutation,
    useDeleteUserAvatarMutation,
    useUpdateUserNameMutation,
    useUpdateUserEmailMutation,
    useUpdateUserPasswordMutation,
    useDeleteUserAccountMutation,
    useAdminGetUsersMutation,
    useAdminUpdateUserMutation,
    useAdminArchiveUserMutation,
    useAdminRestoreUserMutation,
} = userApi;
