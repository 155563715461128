import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Main from '../screens/layouts/Main';
import CharactersScreen from '../screens/CharactersScreen';

const HomeScreen = lazy(() => import('../screens/HomeScreen'));
const AboutScreen = lazy(() => import('../screens/AboutScreen'));
const AdminDashboardScreen = lazy(() => import('../screens/admin/AdminDashboardScreen'));
const TermsScreen = lazy(() => import('../screens/TermsScreen'));
const AccountScreen = lazy(() => import('../screens/AccountScreen'));
const VerifiesEmailScreen = lazy(() => import('../screens/auth/VerifiesEmailScreen'));
const LoginScreen = lazy(() => import('../screens/auth/LoginScreen'));
const MaintenanceScreen = lazy(() => import('../screens/error/MaintenanceScreen'));
const RegisterScreen = lazy(() => import('../screens/auth/RegisterScreen'));
const ForgotPasswordScreen = lazy(() => import('../screens/auth/ForgotPasswordScreen'));
const ResetPasswordScreen = lazy(() => import('../screens/auth/ResetPasswordScreen'));
const ContactScreen = lazy(() => import('../screens/ContactScreen'));
const LandingScreen = lazy(() => import('../screens/LandingScreen'));
const ResendVerifyEmailScreen = lazy(() => import('../screens/auth/ResendVerifyEmailScreen'));
const ErrorScreen = lazy(() => import('../screens/error/ErrorScreen'));
const TestScreen = lazy(() => import('../screens/TestScreen'));

const routes = (auth: boolean, settings: UserSettings) => {
    const notAuthPath = '/';

    return [
        //Main Routes
        {
            path: '/',
            element: <Main />,
            children: [
                { path: '*', element: <ErrorScreen /> },
                {
                    path: '/',
                    element: auth ? <LandingScreen /> : <Navigate to={'/login'} />,
                },
                { path: 'maintenance', element: <MaintenanceScreen /> },
                { path: 'about', element: <AboutScreen /> },
                { path: 'contact', element: <ContactScreen /> },
                { path: 'login', element: !auth ? <LoginScreen /> : <Navigate to={notAuthPath} /> },
                { path: 'register', element: !auth ? <RegisterScreen /> : <Navigate to={notAuthPath} /> },
                { path: 'password/forgot', element: !auth ? <ForgotPasswordScreen /> : <Navigate to={notAuthPath} /> },
                {
                    path: 'password/reset/:token',
                    element: !auth ? <ResetPasswordScreen /> : <Navigate to={notAuthPath} />,
                },
                { path: 'email/verify/:userId/:emailHash', element: <VerifiesEmailScreen /> },
                { path: 'email/verify/resend', element: <ResendVerifyEmailScreen /> },
                { path: '/settings', element: auth ? <AccountScreen /> : <Navigate to={notAuthPath} /> },
                { path: 'terms', element: <TermsScreen /> },
                { path: 'home', element: auth ? <HomeScreen /> : <Navigate to={notAuthPath} /> },
                { path: 'test', element: <TestScreen /> },

                //Auth'd User routes
                { path: '/character', element: auth ? <CharactersScreen /> : <Navigate to={notAuthPath} /> },
            ],
        },
        //Admin Routes
        {
            path: 'admin',
            element: settings?.admin ? <Main /> : <Navigate to={notAuthPath} />,
            children: [{ path: '/admin', element: <AdminDashboardScreen /> }],
        },
    ];
};

export default routes;
