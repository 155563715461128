import {
    BaseQueryApi,
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from './store';

const baseUrl = `${import.meta.env.VITE_SCHEMA}://${import.meta.env.VITE_URI}${
    import.meta.env.VITE_PORT ? `:${import.meta.env.VITE_PORT}` : ''
}`;

const prefixedUrl = `${baseUrl}/${import.meta.env.VITE_PREFIX}/`;

//Exceptions to prevent content-type from being manually set
const exceptions = ['updateUserAvatar'];

type ApiType = BaseQueryApi & { extra: { refresh?: LoginResponse } };

// Base query override
const baseQuery = fetchBaseQuery({
    baseUrl: prefixedUrl,
    prepareHeaders: (headers, api) => {
        //Get Tokens and SocketID from store
        const token = (api.getState() as RootState).auth.accessToken;
        const socketId = (api.getState() as RootState).app.socketId;

        //Set auth token for all requests
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        //Required for socket
        if (socketId) {
            headers.set('X-Socket-ID', socketId);
        }

        //Did we get a new auth token passed?  Let's use that in case store is stale
        if ((api as ApiType)?.extra?.refresh) {
            headers.set('authorization', `Bearer ${(api as ApiType).extra.refresh?.access_token ?? ''}`);
        }

        !exceptions.includes(api.endpoint) && headers.set('content-type', 'application/json');
        headers.set('accept', 'application/json');
        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await baseQuery(args, api, extraOptions);

    // Did we get a 401 not authorized?
    if (result.error && result.error.status === 401) {
        //Try to refresh token
        const refreshResult = await baseQuery(
            {
                url: `${baseUrl}/api/v1/auth/refresh`,
                body: {
                    grant_type: 'refresh_token',
                    refresh_token: (api.getState() as RootState).auth.refreshToken,
                    client_id: import.meta.env.VITE_CLIENT_ID,
                    client_secret: import.meta.env.VITE_CLIENT_SECRET,
                    scope: '',
                },
                method: 'POST',
            },
            api,
            extraOptions
        );

        //Refresh was successful, dispatch original request again
        if (refreshResult.data) {
            api.dispatch({ payload: refreshResult.data as LoginResponse, type: 'auth/refresh' });
            api.extra = { refresh: await refreshResult.data };

            result = await baseQuery(args, api, extraOptions);
        }
        //Refresh failed, logout
        else {
            api.dispatch({ type: 'auth/logout' });
        }
    }
    //always return result
    return result;
};

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions: {}
) => {
    return baseQueryWithReauth(args, api, extraOptions);
};

const apiSlice = createApi({
    reducerPath: 'api',
    tagTypes: ['User'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({}),
});

export default apiSlice;
// export const {} = apiSlice -- placeholder in case endpoints are added
