import { styled } from '@mui/material/styles';
import { drawerWidth } from '../config/theme';
import { keyframes, SwipeableDrawer } from '@mui/material';

const Drawer = styled(SwipeableDrawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'animation',
})<{ open: boolean; animation: 0 | 1 | null }>(({ theme, open = false, animation }) => ({
    width: `calc(${theme.spacing(8)} + 1px)`,
    zIndex: theme.zIndex.drawer,
    position: 'absolute',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    border: '11px solid blue',
    [theme.breakpoints.down('sm')]: {
        width: 0,
        ...(animation === 1 && {
            animation: `${keyframes`
              0% {
                width: 0;
              }
              100% {
                width: ${drawerWidth}px;
              }
            `} .5s forwards ease`,

            overflowX: 'hidden',
            '& .MuiDrawer-paper': {
                animation: `${keyframes`
                  0% {
                    width: 0;
                  }
                  100% {
                    width: ${drawerWidth}px;
                  }
                `} .5s forwards ease`,
                overflowX: 'hidden',
            },
        }),
        ...(animation === 0 && {
            animation: `${keyframes`
              0% {
                width: ${drawerWidth}px;
              }
              100% {
                width: 0;
              }
            `} .5s forwards ease`,
            '& .MuiDrawer-paper': {
                animation: `${keyframes`
                  0% {
                    width: ${drawerWidth}px;
                  }
                  100% {
                    width: 0;
                  }
                `} .5s forwards ease`,
            },
        }),
    },
    [theme.breakpoints.up('sm')]: {
        ...(animation === 1 && {
            animation: `${keyframes`
              0% {
                width: calc(${theme.spacing(8)} + 1px);
              }
              100% {
                width: ${drawerWidth}px;
              }
            `} .5s forwards ease`,

            overflowX: 'hidden',
            '& .MuiDrawer-paper': {
                animation: `${keyframes`
                  0% {
                    width: calc(${theme.spacing(8)}px + 1px);
                  }
                  100% {
                    width: ${drawerWidth}px;
                  }
                `} .5s forwards ease`,
                overflowX: 'hidden',
            },
        }),
        ...(animation === 0 && {
            animation: `${keyframes`
              0% {
                width: ${drawerWidth}px;
              }
              100% {
                width: calc(${theme.spacing(8)}px + 1px);
              }
            `} .5s forwards ease`,
            '& .MuiDrawer-paper': {
                animation: `${keyframes`
                  0% {
                    width: ${drawerWidth}px;
                  }
                  100% {
                    width: calc(${theme.spacing(8)}px + 1px);
                  }
                `} .5s forwards ease`,
            },
        }),
    },
}));

export default Drawer;
