import { createMigrate } from 'redux-persist';

/**
 * Latest Migration Version is the current Redux RootState
 */

type UserRootStateV1 = AppState;

const persistMigrations = {};

type MigrationState = UserRootStateV1;

export const userMigrate = createMigrate<MigrationState>(persistMigrations);

export const userVersion = 1;
