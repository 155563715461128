import React from 'react';
import AccordionDetails from './Sidebar/AccordionDetails';
import {
    Accordion,
    AccordionSummary,
    Box,
    keyframes,
    Paper,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import DrawerHeader from '../components/DrawerHeader';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ResourcesLink from '../components/ResourcesLink';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import BiotechIcon from '@mui/icons-material/Biotech';
import TaskIcon from '@mui/icons-material/Task';
import HelpIcon from '@mui/icons-material/Help';
import ArticleIcon from '@mui/icons-material/Article';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../redux/hooks';
import { useGetUserQuery } from '../redux/features/userApi';

import UserMenu from './Sidebar/UserMenu';
import { drawerWidth } from '../config/theme';

const Sidebar: React.FC<{ open: boolean; setOpen: Function; animation: 0 | 1 | null }> = ({
    open: sidebarOpen,
    setOpen,
    animation,
}) => {
    const theme = useTheme();
    const closedWidth = theme.spacing(8);
    const { classes } = styles({ animation, closedWidth });
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const auth = useAppSelector((state) => state.auth.status);
    const user = useGetUserQuery(null, { skip: !auth });
    /**
     * Sidebar section toggle
     * { section: true}
     * { about: true, help: false}
     */
    const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>({});

    //Is Sidebar open? If not close all menus
    React.useEffect(() => {
        //First check if we're on a mobile device
        // if so we don't close the menus as the drawer hides entirely
        !mobile && !sidebarOpen && setExpanded({});
    }, [sidebarOpen, mobile]);

    React.useEffect(() => {}, [expanded]);

    //Check if section is expanded
    const isExpanded = (key: string) => {
        return !!expanded?.[key];
    };

    const handleClick = (key: string) => {
        //if menu is closed (clicked on charm, let's open the full sidebar)
        !mobile && !sidebarOpen && setOpen(true);

        //Expand the section clicked
        setExpanded({ ...expanded, [key]: !expanded?.[key] });
    };

    return (
        <Box component={Paper} className={classes.root}>
            <DrawerHeader />
            <UserMenu {...{ closedWidth, animation }} />
            <Accordion disableGutters expanded={false} onChange={() => handleClick('home')}>
                <AccordionSummary
                    expandIcon={sidebarOpen && <span onClick={() => handleClick('')} />}
                    aria-controls={'orgs-content'}
                    id={'orgs-header'}
                >
                    <FormatListBulletedIcon />
                    <div className={classes.summary}>
                        <ResourcesLink
                            style={{
                                color: theme.palette.text.primary,
                                display: 'flex',
                                textDecoration: 'none',
                                background: 'transparent',
                                justifyContent: 'left',
                            }}
                            to={'/home'}
                        >
                            <Typography className={classes.menuTitle}>Home</Typography>
                        </ResourcesLink>
                    </div>
                </AccordionSummary>
            </Accordion>
            {!auth && (
                <>
                    <Accordion disableGutters={true} expanded={false} onChange={() => handleClick('home')}>
                        <AccordionSummary
                            expandIcon={sidebarOpen && <span onClick={() => handleClick('')} />}
                            aria-controls={'orgs-content'}
                            id={'orgs-header'}
                        >
                            <LoginIcon />
                            <div className={classes.summary}>
                                <ResourcesLink
                                    style={{
                                        color: theme.palette.text.primary,
                                        display: 'flex',
                                        textDecoration: 'none',
                                        background: 'transparent',
                                        justifyContent: 'initial',
                                    }}
                                    to={'/login'}
                                >
                                    <Typography className={classes.menuTitle}>Login</Typography>
                                </ResourcesLink>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                    <Accordion disableGutters={true} expanded={false} onChange={() => handleClick('home')}>
                        <AccordionSummary
                            expandIcon={sidebarOpen && <span onClick={() => handleClick('')} />}
                            aria-controls={'orgs-content'}
                            id={'orgs-header'}
                        >
                            <AppRegistrationIcon />
                            <div className={classes.summary}>
                                <ResourcesLink
                                    style={{
                                        color: theme.palette.text.primary,
                                        display: 'flex',
                                        textDecoration: 'none',
                                        background: 'transparent',
                                        justifyContent: 'initial',
                                    }}
                                    to={'/register'}
                                >
                                    <Typography className={classes.menuTitle}>Register</Typography>
                                </ResourcesLink>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                </>
            )}
            {user.currentData?.is_admin && (
                <Accordion disableGutters={true} expanded={isExpanded('admin')} onChange={() => handleClick('admin')}>
                    <AccordionSummary
                        expandIcon={sidebarOpen && <ExpandMore onClick={() => handleClick('admin')} />}
                        aria-controls={'admin-content'}
                        id={'admin-header'}
                    >
                        <AdminPanelSettingsIcon />
                        <div className={classes.summary}>
                            <Typography className={classes.menuTitle}>Admin</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResourcesLink to={'/admin'}>
                            <DashboardIcon fontSize={'medium'} />
                            <Typography className={classes.menuItem}>Dashboard</Typography>
                        </ResourcesLink>
                        <ResourcesLink
                            external
                            redirect
                            to={`${import.meta.env.VITE_SCHEMA}://${import.meta.env.VITE_URI}${
                                import.meta.env.VITE_PORT ? `:${import.meta.env.VITE_PORT}` : ''
                            }/mission/telescope`}
                        >
                            <BiotechIcon fontSize={'medium'} />
                            <Typography className={classes.menuItem}>Telescope</Typography>
                        </ResourcesLink>
                        <ResourcesLink
                            external
                            redirect
                            to={`${import.meta.env.VITE_SCHEMA}://${import.meta.env.VITE_URI}${
                                import.meta.env.VITE_PORT ? `:${import.meta.env.VITE_PORT}` : ''
                            }/mission/horizon`}
                        >
                            <TaskIcon fontSize={'medium'} />
                            <Typography className={classes.menuItem}>Horizon</Typography>
                        </ResourcesLink>
                        <ResourcesLink
                            external
                            redirect
                            to={`${import.meta.env.VITE_SCHEMA}://${import.meta.env.VITE_URI}${
                                import.meta.env.VITE_PORT ? `:${import.meta.env.VITE_PORT}` : ''
                            }/mission/logs`}
                        >
                            <ArticleIcon fontSize={'medium'} />
                            <Typography className={classes.menuItem}>Logs</Typography>
                        </ResourcesLink>
                    </AccordionDetails>
                </Accordion>
            )}
            {auth && (
                <Accordion disableGutters expanded={isExpanded('demo')} onChange={() => handleClick('demo')}>
                    <AccordionSummary
                        expandIcon={sidebarOpen && <ExpandMore onClick={() => handleClick('demo')} />}
                        aria-controls={'demo-content'}
                        id={'demo-header'}
                    >
                        <HelpIcon />
                        <div className={classes.summary}>
                            <Typography className={classes.menuTitle}>Demo</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResourcesLink to={'/test'}>
                            <InfoIcon fontSize={'medium'} />{' '}
                            <Typography className={classes.menuItem}>Test Importer</Typography>
                        </ResourcesLink>
                    </AccordionDetails>
                </Accordion>
            )}
            <Accordion disableGutters expanded={isExpanded('help')} onChange={() => handleClick('help')}>
                <AccordionSummary
                    expandIcon={sidebarOpen && <ExpandMore onClick={() => handleClick('help')} />}
                    aria-controls={'help-content'}
                    id={'help-header'}
                >
                    <HelpIcon />
                    <div className={classes.summary}>
                        <Typography className={classes.menuTitle}>Help</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <ResourcesLink to={'/about'}>
                        <InfoIcon fontSize={'medium'} /> <Typography className={classes.menuItem}>About Us</Typography>
                    </ResourcesLink>
                    <ResourcesLink to={'/contact'}>
                        <ContactSupportIcon fontSize={'medium'} />{' '}
                        <Typography className={classes.menuItem}>Contact Us</Typography>
                    </ResourcesLink>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const styles = makeStyles<{ closedWidth: string; animation: 0 | 1 | null }>()(
    (theme: Theme, { closedWidth, animation }) => ({
        root: {
            width: closedWidth,
            margin: 0,
            '& .MuiAccordionSummary-expandIconWrapper': {
                overflowX: 'hidden',
            },
            '& .MuiAccordionSummary-root': {
                paddingLeft: `calc(${theme.spacing(7)}/2)`,
            },
            [theme.breakpoints.down('sm')]: {
                width: 0,
                ...(animation === 1 && {
                    animation: `${keyframes`
                      0% {
                        width: 0;
                      }
                      100% {
                        width: ${drawerWidth}px;
                      }
                    `} .5s forwards ease`,
                }),
                ...(animation === 0 && {
                    animation: `${keyframes`
                      0% {
                        width: ${drawerWidth}px;
                      }
                      100% {
                        width: 0;
                      }
                    `} .5s forwards ease`,
                }),
            },
            [theme.breakpoints.up('sm')]: {
                ...(animation === 1 && {
                    animation: `${keyframes`
                      0% {
                        width: ${closedWidth}px;
                      }
                      100% {
                        width: ${drawerWidth}px;
                      }
                    `} .5s forwards ease`,
                }),
                ...(animation === 0 && {
                    animation: `${keyframes`
                      0% {
                        width: ${drawerWidth}px;
                      }
                      100% {
                        width: ${closedWidth}px;
                      }
                    `} .5s forwards ease`,
                }),
            },
        },

        summary: {
            justifyContent: 'initial',
            width: 0,
            display: 'flex',
            marginLeft: theme.spacing(1),
            visibility: 'hidden',
            ...(animation === 1 && {
                visibility: 'visible',
                animation: `${keyframes`
                  0% {
                    width: 0;
                  }
                  100% {
                    width: 100%;
                  }
                `} .6s forwards ease`,
            }),
            ...(animation === 0 && {
                visibility: 'hidden',
                animation: `${keyframes`
                  0% {
                    width: 100%;
                  }
                  100% {
                    width: 0;
                  }
                `} .6s forwards ease`,
            }),
        },

        menuTitle: {
            marginLeft: theme.spacing(1),
            width: 0,
            height: 0,
            visibility: 'hidden',
            ...(animation === 0 && {
                animation: `${keyframes`
                  0% {
                    with: 100%;
                    height: 100%;
                  }
                  100% {
                    width: 0;
                  }
                `} 1s forwards ease`,
            }),
            ...(animation === 1 && {
                visibility: 'visible',
                animation: `${keyframes`
                  0% {
                  }
                  100% {
                    width: 100%;
                    height: 100%;
                  }
                `} 1s forwards ease`,
            }),
        },

        menuItem: {
            paddingLeft: theme.spacing(1),
        },
    })
);

export default Sidebar;
