import { createSlice } from '@reduxjs/toolkit';
import userApi from '../features/userApi';

const initialState: UserState = {
    data: null!,
    name: null!,
    settings: {
        admin: false,
        developer: false,
        verified: false,
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
            state.name = payload.name;
            state.settings = {
                admin: payload.is_admin,
                developer: payload.is_developer,
                verified: payload.is_verified,
            };
            state.data = payload;
        });
    },
});

export default userSlice;
// eslint-disable-next-line no-empty-pattern
export const {} = userSlice.actions;
